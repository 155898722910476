import type { ICategory } from 'entities/categories/model/ICategory';
import type { IFilterMeta } from 'entities/filters';
import type { ITag } from 'entities/tags';

type UseCategorySeo = (
  category: ICategory,
  tags: ITag[],
  filtersMeta: IFilterMeta,
  breadcrumbs?: { title: string; href: string; clickableLast?: boolean }[]
) => {
  title?: string;
  keywords?: string;
  description?: string;
  canonical: string;
  robots: string;
};

/** Возвращает SEO-данные категории/тега */
export const useCategorySeo: UseCategorySeo = (category, tags, filtersMeta, breadcrumbs) => {
  const tag = tags[tags.length - 1];

  const title = tag?.title || filtersMeta?.title || category?.seo_title;
  const keywords = tag?.keywords || filtersMeta?.keywords || category?.keywords;
  const description = tag?.description || filtersMeta?.description || category?.seo_description;
  let canonical = '';
  let robots = '';

  canonical = 'https://vobaza.ru';

  if (breadcrumbs?.length) {
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    canonical += lastBreadcrumb.href.startsWith('/') ? lastBreadcrumb.href : `/${lastBreadcrumb.href}`;
  }

  if (tag?.canonical) {
    canonical = tag.canonical.startsWith('https://vobaza.ru/')
      ? tag.canonical
      : tag.canonical.startsWith('/')
      ? `https://vobaza.ru${tag.canonical}`
      : `https://vobaza.ru/${tag.canonical}`;
  }

  const index =
    tag?.robots?.find((item) => item.toLowerCase() === 'index' || item.toLowerCase() === 'noindex')?.toLowerCase() ||
    'index';
  const follow =
    tag?.robots?.find((item) => item.toLowerCase() === 'follow' || item.toLowerCase() === 'nofollow')?.toLowerCase() ||
    'follow';
  robots = `${index}, ${follow}`;

  return {
    title,
    keywords,
    description,
    canonical,
    robots,
  };
};
