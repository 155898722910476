import {ExpressFilterSVG} from 'assets/svg/ExpressFilterSVG';
import { FC, useEffect, useState, useRef } from 'react';

import type { IGoodCard } from 'entities/products/model/IGood';
import { ISticker } from 'src/models/ISticker';
import { useAdvancedRouter } from 'shared/lib/useAdvancedRouter';
import { useSelector } from 'shared/lib/hooks/useSelector';

import { CategoryTags } from 'widgets/categories/ui/CategoryTags';
import { Pagination } from '@nebo-team-dev/vobaza.ui.pagination/dist';
import { Title } from '@nebo-team-dev/vobaza.ui.title';
import { CategoryFilters } from '../../widgets/categories';
import { CartModal } from '../../widgets/products';
import { ProductsList } from '../../widgets/products';
import Toggle from 'shared/ui/Toggle';
import Preloader from 'shared/ui/Preloader';

import styles from './styles.module.scss';
import {FastDeliveryFilterSVG} from "../../assets/svg/FastDeliveryFilterSVG";

type Props = {
  withoutExpress?: boolean;
  categorySlug?: string;
  isExpress?: boolean;
  isFastDelivery?: boolean;
  goods: IGoodCard[];
  withFilters?: boolean;
  isListLoading?: boolean;
  meta: {
    list: {
      count: number;
      pages_count: number;
    };
    stickers?: ISticker[];
  };
};

export const GoodsBlock: FC<Props> = ({
  withoutExpress,
  withFilters,
  categorySlug,
  isExpress = false,
  isFastDelivery = false,
  isListLoading,
  goods,
  meta,
}) => {
  const [isOnlyExpress, setIsOnlyExpress] = useState(isExpress);
  const [isOnlyFastDelivery, setIsOnlyFastDelivery] = useState(isFastDelivery);
  const [isLoading, setIsLoading] = useState(true);
  const isExpressSwitched = useRef(false);
  const isFastDeliverySwitched = useRef(false);
  const currentTags = useSelector((state) => state.tags.currentTags);
  const currentTagsLevel = useSelector((state) => state.tags.tags);

  const hasInvalidTags = useSelector((state) => state.tags.hasInvalidTags);
  const hasInvalidFilters = useSelector((state) => state.filters.hasInvalidFilters);

  const { router } = useAdvancedRouter();
  const { page } = router.query;

  const toggleRoute = (route, deliveryType) => {
    const query = router.query;
    delete query['page'];
    delete query['id'];

    const queryWithoutUtm = Object.fromEntries(
        Object.entries(query).filter(
            (item) => item[0] === 'sort' || item[0] === 'city' || item[0] === 'page' || !isNaN(+item[0])
        )
    );

    if (!deliveryType) {
      router.push(
          {
            pathname: router.asPath.split('?')[0].replace(/\/ekspress-dostavka|\/fast-delivery/gui, '') + route,
            query: queryWithoutUtm,
          },
          null,
          { scroll: false }
      );
    } else {
      delete query['city'];
      router.push(
          {
            pathname: router.asPath.split('?')[0].replace(route, ''),
            query: queryWithoutUtm,
          },
          null,
          { scroll: false }
      );
    }
  }

  const toggleIsOnlyExpress = () => {
    if (isExpressSwitched.current || isListLoading) return;
    isExpressSwitched.current = true;
    isFastDeliverySwitched.current = false;
    setIsLoading(true);
    setIsOnlyExpress(!isOnlyExpress);
    if (isOnlyFastDelivery) {
      setIsOnlyFastDelivery(false);
    }

    toggleRoute('/ekspress-dostavka', isOnlyExpress);
  };

  const toggleIsFastDelivery = () => {
    if (isFastDeliverySwitched.current || isListLoading) return;
    isFastDeliverySwitched.current = true;
    isExpressSwitched.current = false;
    setIsLoading(true);
    setIsOnlyFastDelivery(!isOnlyFastDelivery);
    if(isOnlyExpress) {
      setIsOnlyExpress(false);
    }

    toggleRoute('/fast-delivery', isOnlyFastDelivery);
  };

  useEffect(() => {
    if (isExpressSwitched.current && !isListLoading) isExpressSwitched.current = false;
  }, [router.asPath, isListLoading]);

  useEffect(() => {
    if (isFastDeliverySwitched.current && !isListLoading) isFastDeliverySwitched.current = false;
  }, [router.asPath, isListLoading]);

  const onChangePagination = (value: number) => {
    router.replace({
      query: {
        ...router.query,
        page: value,
      },
    });
  };

  useEffect(() => {
    setIsOnlyExpress(isExpress);
  }, [isExpress]);

  useEffect(() => {
    setIsOnlyFastDelivery(isFastDelivery);
  }, [isFastDelivery]);

  useEffect(() => {
    setIsLoading(false);
  }, [goods]);

  return (
    <div className={styles.goodsBlock}>
      <CartModal />
      {Boolean(withFilters) && (
        <>
          <CategoryTags
            currentTags={currentTags}
            currentTagsLevel={currentTagsLevel}
            setIsLoading={setIsLoading}
            place="HEADER"
          />
          {!withoutExpress && (
            <div className={styles.goodsFilters}>
              <Toggle
                isActive={isOnlyExpress}
                onClick={toggleIsOnlyExpress}
                disabled={isExpressSwitched.current || isListLoading}
              >
                <div className={styles.toggleContent}>
                  <ExpressFilterSVG />
                  <span className={styles.toggleText}>Экспресс</span>
                </div>
              </Toggle>
              <Toggle
                  isActive={isOnlyFastDelivery}
                  onClick={toggleIsFastDelivery}
                  disabled={isFastDeliverySwitched.current || isListLoading}
              >
                <div className={styles.toggleContent}>
                  <FastDeliveryFilterSVG/>
                  <span className={styles.toggleText}>Быстрая доставка</span>
                </div>
              </Toggle>
            </div>
          )}
          <div className={styles.filtersBlock}>
            <CategoryFilters categorySlug={categorySlug} setIsLoading={setIsLoading} isLoading={isLoading} />
          </div>
        </>
      )}
      {(!goods?.length || hasInvalidFilters) && !isListLoading && (
        <Title element="h3" style={{ marginTop: 20 }}>
          Нет товаров, соответствующих условию
        </Title>
      )}
      {hasInvalidTags && !!goods.length && (
        <Title element="h3" style={{ marginTop: 20 }}>
          При загрузке данных произошла ошибка
        </Title>
      )}
      {isListLoading ? (
        <Preloader />
      ) : goods?.length > 0 && !isListLoading ? (
        <div className={`${styles.goodsList} ${isLoading ? styles.busy : ''}`}>
          <ProductsList goods={goods} stickers={meta?.stickers} />
        </div>
      ) : (
        <div className={styles.goodsListEmpty}>По вашему запросу ничего не найдено</div>
      )}
      {meta?.list?.pages_count > 1 && (
        <div className={styles.pagination}>
          <Pagination
            variation="secondary"
            pageCount={meta.list.pages_count}
            activePage={+page || 1}
            onChange={onChangePagination}
          />
        </div>
      )}

      <CategoryTags
        currentTags={currentTags}
        currentTagsLevel={currentTagsLevel}
        setIsLoading={setIsLoading}
        place="FOOTER"
      />
    </div>
  );
};
