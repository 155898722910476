import { FC, useEffect, useState } from 'react';

import { InputCheckbox } from '@nebo-team-dev/vobaza.ui.inputs.input-checkbox/dist';
import { IFilterSelectVariant } from '@nebo-team-dev/vobaza.ui.filter-select/dist';
import { FilterSelect } from '@nebo-team-dev/vobaza.ui.filter-select/dist';
import { RangeBlock } from '@nebo-team-dev/vobaza.ui.range/dist';

import useDebounce from 'shared/lib/hooks/useDebounce';
import type { IFilter, IFilterFront } from 'entities/filters/model/IFilter';
import { sortFilterSelectVariants } from 'entities/filters/lib/sortFilterSelectVariant';

import styles from './styles.module.scss';

type Props = {
  full?: boolean;
  filter: IFilter;
  baseFilter: IFilter;
  localCurrentFilter: IFilterFront;
  addFilter: (filter: IFilterFront) => void;
  localFilters: Record<number, IFilterFront>;
  setLocalCurrentFilters: (localFilters: Record<number, IFilterFront>) => void;
};
type FilterProps = Omit<Props, 'setLocalCurrentFilters' | 'localFilters'> & {
  onFilterChange: (value: string | number | [number, number]) => void;
};
// TODO: Очень криво работает этот фильтр, надо отрефакторить
const NumericFilter: FC<FilterProps> = ({ onFilterChange, filter, full = false, localCurrentFilter, addFilter }) => {
  const [values, setValues] = useState<[number, number]>([filter.meta.min, filter.meta.max]);
  useEffect(() => {
    setValues([filter.meta.min, filter.meta.max]);
  }, [filter]);

  useEffect(() => {
    if (localCurrentFilter) {
      switch (true) {
        case localCurrentFilter.values[0] >= filter.meta.min && values[1] <= filter.meta.max:
          setValues(localCurrentFilter.values as [number, number]);
          break;
        case localCurrentFilter.values[0] <= filter.meta.min && values[1] >= filter.meta.max:
          setValues([filter.meta.min, filter.meta.max]);
          break;
        case localCurrentFilter.values[0] >= filter.meta.min && values[1] >= filter.meta.max:
          setValues([localCurrentFilter.values[0], filter.meta.max]);
          break;
        case localCurrentFilter.values[0] <= filter.meta.min && values[1] <= filter.meta.max:
          setValues([filter.meta.min, localCurrentFilter.values[1]]);
          break;
      }

      return;
    }
  }, []);

  const onButtonClick = (newValues: [number, number]) => {
    addFilter({
      id: filter.id,
      name: filter.display_name || filter.name,
      value_type: filter.value_type,
      display_type: filter.display_type,
      values: newValues,
    });
  };

  const onChange = (val: [number, number]) => {
    if (val[0] === filter.meta.min && val[1] === filter.meta.max) return;
    onFilterChange(val);
  };
  const debouncedOnChange = useDebounce(onChange, 200);

  return (
    <div>
      {full ? (
        <div>
          {values[0] >= filter.meta.min && values[1] <= filter.meta.max && (
            <RangeBlock
              min={filter.meta.min}
              max={filter.meta.max}
              incomeValues={values}
              onChange={debouncedOnChange}
            />
          )}
        </div>
      ) : (
        <FilterSelect
          variation="secondary"
          variants={[
            { code: 'min', value: filter.meta.min },
            { code: 'max', value: filter.meta.max },
          ]}
          type="range"
          incomeValues={values}
          placeholder={filter.display_name || filter.name}
          buttonText="Показать"
          onButtonClick={onButtonClick}
        />
      )}
    </div>
  );
};

const disableCheckboxes = (baseFilter: IFilter, filter: IFilter) =>
  baseFilter?.meta?.items
    ?.map((item) => {
      return {
        value: item === 'true' ? 'Да' : item === 'false' ? 'Нет' : item,
        code: item,
        isActive: false,
        disabled: !filter.meta.items.find((val) => val === item),
      };
    })
    .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));

const ListedFilter: FC<FilterProps> = ({
  filter,
  baseFilter,
  full = false,
  localCurrentFilter,
  addFilter,
  onFilterChange,
}) => {
  const [values, setValues] = useState<IFilterSelectVariant[]>(disableCheckboxes(baseFilter, filter));

  const [isTouched, setIsTouched] = useState(false);

  const sortedValues = values ? sortFilterSelectVariants(values) : values;

  useEffect(() => {
    const isPriceManyFromMany = filter.display_type === 'MANY_FROM_MANY' && filter.value_type === 'PRICE';

    if (localCurrentFilter) {
      const currentValues = isPriceManyFromMany
        ? localCurrentFilter.values.map((item) => (Number(item) / 100).toString())
        : localCurrentFilter.values;

      setValues((prevArray) => {
        const newRes = prevArray.map((item) => {
          if (currentValues.includes(item.code)) {
            return {
              ...item,
              isActive: true,
            };
          }
          return { ...item, isActive: false };
        });

        return newRes;
      });
    } else {
      setValues(
        (prevArray) =>
          prevArray?.map((item) => {
            return { ...item, isActive: false };
          }) || []
      );
    }
    setIsTouched(false);
  }, [localCurrentFilter, filter]);
  const changeValues = (val) => {
    setIsTouched(true);
    setValues(
      values.map((value) => {
        if (value.code === val.code)
          return {
            ...val,
          };
        return value;
      })
    );
  };
  const onClick = (e?) => {
    const isPriceManyFromMany = filter.display_type === 'MANY_FROM_MANY' && filter.value_type === 'PRICE';
    e && e.preventDefault();
    if (!isTouched) return;

    const newValues = [];
    values
      .filter((item) => item.isActive)
      .forEach((item) => {
        const code = isPriceManyFromMany ? Number(item.code) * 100 : item.code;
        newValues.push(code);
      });

    addFilter({
      id: filter.id,
      name: filter.display_name || filter.name,
      display_type: filter.display_type,
      values: newValues,
    });
  };
  useEffect(() => {
    const isPriceManyFromMany = filter.display_type === 'MANY_FROM_MANY' && filter.value_type === 'PRICE';

    const filteredBaseFilters = baseFilter?.meta?.items
      ?.map((item) => {
        let isActive = false;
        if (localCurrentFilter?.values.includes(isPriceManyFromMany ? (Number(item) * 100).toString() : item)) {
          isActive = true;
        }

        return {
          value: item === 'true' ? 'Да' : item === 'false' ? 'Нет' : item,
          code: item,
          isActive,
          disabled: !filter.meta.items.find((val) => val === item),
        };
      })
      .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));

    setValues(filteredBaseFilters);
  }, [baseFilter]);

  if (!baseFilter?.meta?.items) return null;

  return (
    <div>
      {full ? (
        <div>
          {sortedValues?.map((item) => (
            <div className={styles.filterListItem} key={item.code}>
              <InputCheckbox
                variation="secondary"
                label={item.value}
                initialValue={item.isActive}
                onChange={() => {
                  changeValues({ ...item, isActive: !item.isActive });
                  onFilterChange(item.code);
                }}
                disabled={item.disabled}
              />
            </div>
          ))}
        </div>
      ) : (
        <FilterSelect
          variation="secondary"
          variants={sortedValues}
          type="checkbox"
          placeholder={filter.display_name || filter.name}
          buttonText="Показать"
          onChange={changeValues}
          onButtonClick={onClick}
          lockAfterUnchecked
          onlyPlaceholder
        />
      )}
    </div>
  );
};

export const Filter: FC<Props> = ({
  setLocalCurrentFilters,
  localFilters,
  localCurrentFilter,
  filter,
  baseFilter,
  ...props
}) => {
  const addOrRemoveFilter = (value: string | number | [number, number], filterValues: Array<string | number>) => {
    if (Array.isArray(value)) {
      return value;
    }
    if (filterValues.includes(value)) {
      return filterValues.filter((val) => val !== value);
    }
    return [...filterValues, value];
  };

  const onFilterChange = (value: string | number | [number, number]) => {
    const { display_type, name, display_name, value_type, id } = filter;
    if (!localFilters) {
      setLocalCurrentFilters({
        [id]: { name, id, display_type, display_name, value_type, values: Array.isArray(value) ? value : [value] },
      });
      return;
    }
    if (!localFilters[id]) {
      setLocalCurrentFilters({
        ...localFilters,
        [id]: { name, id, display_type, display_name, value_type, values: Array.isArray(value) ? value : [value] },
      });
      return;
    }
    setLocalCurrentFilters({
      ...localFilters,
      [id]: {
        ...localFilters[id],
        values: Array.isArray(value) ? value : addOrRemoveFilter(value, localFilters[id].values),
      },
    });
  };

  return (
    <div>
      {
        {
          NUMERIC_RANGE: (
            <NumericFilter
              localCurrentFilter={localCurrentFilter}
              onFilterChange={onFilterChange}
              filter={filter}
              baseFilter={baseFilter}
              {...props}
            />
          ),
          MANY_FROM_MANY: (
            <ListedFilter
              localCurrentFilter={localCurrentFilter}
              onFilterChange={onFilterChange}
              filter={filter}
              baseFilter={baseFilter}
              {...props}
            />
          ),
        }[filter.display_type]
      }
    </div>
  );
};
