import classNames from 'classnames';
import React, { Dispatch, FC, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';

import type { ITag } from 'entities/tags';
import type { DeviceType } from 'entities/tags/model/ITag';
import { useAdvancedRouter } from 'shared/lib/useAdvancedRouter';
import { useMatchScreen } from 'shared/lib/hooks/useMatchScreen';
import { filterTagsByScreenWidth } from 'widgets/categories/lib/filterTagsByScreenWidth';

import { CategoryTagItem } from 'entities/tags/ui/CategoryTagItem';

import styles from './styles.module.scss';
import filterButtonStyles from 'global/styles/FilterButton.module.scss';

type Props = {
  currentTagsLevel: ITag[];
  currentTags: ITag[];
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  place: 'HEADER' | 'FOOTER';
};

export const CategoryTags: FC<Props> = ({ setIsLoading, place, currentTags, currentTagsLevel }) => {
  const [showMore, setShowMore] = useState<Record<DeviceType, boolean>>({
    MOBILE: false,
    DESKTOP: false,
  });
  const { router } = useAdvancedRouter();

  const filteredTagsByPlace = currentTagsLevel.filter((item) => item.placement === place);

  const currentTag = currentTags[currentTags.length - 1];
  const matchScreen = useMatchScreen(500);
  const currentDevice: DeviceType = matchScreen === null ? null : matchScreen ? 'MOBILE' : 'DESKTOP';

  const filteredTagsByScreen = filterTagsByScreenWidth(
    filteredTagsByPlace,
    currentDevice,
    !!showMore[currentDevice],
    currentTag
  );

  const isVisibleMoreButton = filteredTagsByScreen.length < filteredTagsByPlace.length;
  const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
  const getTagUrl = (tagId: number) => {
    const tag = filteredTagsByPlace.find((item) => item?.id === tagId);

    return router.asPath.includes('/ekspress-dostavka') ? tag?.url + '/ekspress-dostavka' : tag?.url;
  };

  const onTagClick = () => {
    setIsLoading(true);
  };

  const handleClick = () => {
    setShowMore((prev) => {
      return { ...prev, [currentDevice]: !prev[currentDevice] };
    });
  };

  useIsomorphicLayoutEffect(() => {
    setShowMore((prev) => {
      return {
        ...prev,
        [currentDevice]: false,
      };
    });
  }, [router.query.id]);

  const modClass = styles.categoryTags + ' ' + styles[place.toLocaleLowerCase()];

  return (
    <>
      {Boolean(filteredTagsByPlace?.length) && (
        <div className={modClass}>
          {filteredTagsByScreen.map((tag) => (
            <CategoryTagItem
              key={tag?.id}
              isActive={currentTag?.id === tag?.id}
              tag={tag}
              getTagUrl={getTagUrl}
              onClick={onTagClick}
            />
          ))}

          {(isVisibleMoreButton || showMore[currentDevice]) && (
              <div className={styles.filtersButton}>
                <button className={classNames(filterButtonStyles.filtersButton)}
                        onClick={handleClick}>
                  {showMore[currentDevice] ? 'Скрыть' : 'Показать еще'}
                </button>
              </div>
          )}
        </div>
      )}
    </>
  );
};
