import { IFilter } from "entities/filters";

export const sortFiltersItems = (filters: IFilter[]) => {
  return filters.map((filterItem) => {
    if (filterItem.value_type !== 'PRICE' && filterItem?.meta?.items?.length > 0) {
      const metaItems = [...filterItem.meta.items];
      metaItems.sort((a, b) => {
        const aValue = a.toLocaleLowerCase();
        const bValue = b.toLocaleLowerCase();
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });

      const newFilter = { ...filterItem, meta: { ...filterItem.meta, items: [...metaItems] } };

      return newFilter;
    } else {
      return filterItem;
    }
  });
};