type GetParamsFromQuery = Record<string, string | number | boolean | string[]>;

/** Получение параметров для GET-запросов товаров и фильтров */
export const getParamsFromQuery = (
  initialParams: Record<string, number | string | string[] | boolean>,
  activeFilters: Record<string, string | string[]>
): GetParamsFromQuery => {
  const newParams = { ...initialParams };

  if (activeFilters && Object.keys(activeFilters).length) {
    newParams['filter[include_variants]'] = true;
  }

  Object.entries(activeFilters).forEach(([key, value], index) => {
    newParams[`filter[filters][${index}][id]`] = key;

    if (value?.toString().includes('%-%')) {
      // Фильтр диапазона чисел
      const [min, max] = value.toString().split('%-%');
      newParams[`filter[filters][${index}][min]`] = min;
      newParams[`filter[filters][${index}][max]`] = max;
    } else {
      if (Array.isArray(value)) {
        value.forEach((val, valueIndex) => {
          newParams[`filter[filters][${index}][values][${valueIndex}]`] = val;
        });
      } else {
        newParams[`filter[filters][${index}][values][0]`] = value;
      }
    }
  });

  return newParams;
};
