import React from 'react';

export const FastDeliveryFilterSVG = () => {
    return (
        <svg width="182" height="28" viewBox="0 0 182 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M80 6C80 2.68629 82.6863 0 86 0H169.383C171.38 0 173.246 0.993347 174.361 2.64987L180.497 11.7666C181.406 13.1169 181.406 14.8831 180.497 16.2334L174.361 25.3501C173.246 27.0067 171.38 28 169.383 28H86C82.6863 28 80 25.3137 80 22V6Z" fill="#D9D9D9"/>
            <path d="M80 6C80 2.68629 82.6863 0 86 0H169.383C171.38 0 173.246 0.993347 174.361 2.64987L180.497 11.7666C181.406 13.1169 181.406 14.8831 180.497 16.2334L174.361 25.3501C173.246 27.0067 171.38 28 169.383 28H86C82.6863 28 80 25.3137 80 22V6Z" fill="#B5D8F8"/>
            <path d="M76 6C76 2.68629 78.6863 0 82 0H165.383C167.38 0 169.246 0.993347 170.361 2.64987L176.497 11.7666C177.406 13.1169 177.406 14.8831 176.497 16.2334L170.361 25.3501C169.246 27.0067 167.38 28 165.383 28H82C78.6863 28 76 25.3137 76 22V6Z" fill="#D9D9D9"/>
            <path d="M76 6C76 2.68629 78.6863 0 82 0H165.383C167.38 0 169.246 0.993347 170.361 2.64987L176.497 11.7666C177.406 13.1169 177.406 14.8831 176.497 16.2334L170.361 25.3501C169.246 27.0067 167.38 28 165.383 28H82C78.6863 28 76 25.3137 76 22V6Z" fill="#60A8EB"/>
            <path d="M0 6C0 2.68629 2.68629 0 6 0H160.321C162.31 0 164.169 0.985445 165.286 2.63113L171.476 11.7541C172.396 13.11 172.396 14.89 171.476 16.2459L165.286 25.3689C164.169 27.0146 162.31 28 160.321 28H6C2.6863 28 0 25.3137 0 22V6Z" fill="#D9D9D9"/>
            <path d="M0 6C0 2.68629 2.68629 0 6 0H160.321C162.31 0 164.169 0.985445 165.286 2.63113L171.476 11.7541C172.396 13.11 172.396 14.89 171.476 16.2459L165.286 25.3689C164.169 27.0146 162.31 28 160.321 28H6C2.6863 28 0 25.3137 0 22V6Z" fill="#2E679D"/>
        </svg>
    )
}