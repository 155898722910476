import { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

import type { ICategory } from 'entities/categories/model/ICategory';
import type { ITag } from 'entities/tags';

import { getImageVariantProps } from 'shared/lib/images';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';

import PlaceholderImage from 'assets/images/placeholder_small.webp';

import styles from './styles.module.scss';

type Props = {
  list: ICategory[];
};

const getHref = (parentCategoriesSlugs: string | string[], categorySlug: string, currentTags: ITag[]) => {
  let path = '';

  if (typeof parentCategoriesSlugs === 'object') {
    parentCategoriesSlugs.forEach((slug) => {
      if (slug !== 'ekspress-dostavka' && currentTags.every((tag) => tag.slug !== slug)) path = path + `/${slug}`;
    });
  } else {
    path = `/${parentCategoriesSlugs}`;
  }

  path = `${categorySlug}`;

  if (parentCategoriesSlugs.includes('ekspress-dostavka')) {
    path = path + '/ekspress-dostavka';
  }

  return path;
};

const CatalogList: FC<Props> = ({ list }) => {
  const [categoryiesList, setCategoryiesList] = useState(list);

  const router = useRouter();

  const currentTags = useSelector((state: RootState) => state.tags.currentTags);

  useEffect(() => {
    setCategoryiesList(list);
  }, [list]);

  return (
    <nav className={styles.catalog}>
      {categoryiesList.map((category) => (
        <Link
          key={category.id}
          href={router.query?.id ? getHref(router.query.id, category.url || category.slug, currentTags) : `/${category.slug}`}
          className={`${styles.category} ${category.status === 'HIDDEN' ? styles.categoryHidden : ''} `}
        > 
          <div className={styles.categoryImage}>
            {category.image?.variants?.small_webp?.url || category.image?.variants?.small?.url ? (
              <Image {...getImageVariantProps(category.image.variants, 'small')} alt={category.name} />
            ) : (
              <Image src={PlaceholderImage} alt={category.name} width={100} height={100} />
            )}
          </div>
          <div className={styles.categoryTitle}>{category.name}</div>
          <Icon className={styles.categoryIcon} name="SmallArrowUp" />
        </Link>
      ))}
    </nav>
  );
};

export default CatalogList;
