import React, { FC } from 'react';
import Link from 'next/link';

import type { ITag } from 'entities/tags';
import { parseTagTitle } from 'features/categories';

import styles from './styles.module.scss';

type Props = {
  isActive: boolean;
  tag: ITag;
  getTagUrl: (id: number) => string;
  onClick: () => void;
};

export const CategoryTagItem: FC<Props> = ({ isActive, tag, getTagUrl, onClick }) => {
  const href = getTagUrl(tag?.id);

  return (
    <Link
      href={{ pathname: href }}
      className={`${styles.tag} ${isActive ? styles.active : ''}`}
      onClick={onClick}
      dangerouslySetInnerHTML={{ __html: parseTagTitle(tag?.name) }}
    ></Link>
  );
};
