import React, { FC, MouseEvent, useEffect, useState, BaseSyntheticEvent } from 'react';

import type { IFilter, IFilterFront } from '../../../../entities/filters/model/IFilter';
import { convertFiltersIfPrice } from 'shared/lib/categories/filters/convertFiltersIfPrice';

import { useRouter } from 'next/router';
import { getParamsFromQuery } from 'shared/lib/categories/getParamsFromQuery';
import { sortFiltersItems } from 'widgets/categories/lib/sortFiltersItems';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';
import { Button } from '@nebo-team-dev/vobaza.ui.button/dist';
import Accordeon from '../../../../shared/ui/Accordeon';
import { Filter } from '../../../../entities/filters';
import Preloader from 'shared/ui/Preloader';

import styles from './styles.module.scss';
import { api } from 'global/api';

type Props = {
  isOpen: boolean;
  baseFilters: IFilter[];
  currentFilters: { [key: number]: IFilterFront };
  close: () => void;
  addFilter: (filters: IFilterFront) => void;
  removeAllFilters: () => void;
  setLocalCurrentFilters: (filters: Record<number, IFilterFront>) => void;
  localCurrentFilters: Record<number, IFilterFront>;
};

const FiltersModal: FC<Props> = ({
  localCurrentFilters,
  setLocalCurrentFilters,
  isOpen,
  baseFilters,
  removeAllFilters,
  addFilter,
  close,
}) => {
  const [filtersList, setFiltersList] = useState([]);
  const [isFiltersLoading, setFiltersLoading] = useState(false);
  const router = useRouter();

  const queryWithoutUtm = Object.fromEntries(
    Object.entries(router.query).filter(
      (item) => item[0] === 'sort' || item[0] === 'city' || item[0] === 'id' || !isNaN(+item[0])
    )
  );

  const { id, sort, city, ...activeFilters } = queryWithoutUtm;
  const sortedBaseFilters = sortFiltersItems(baseFilters);

  const menuClickHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  const onModalClose = (event: BaseSyntheticEvent) => {
    if (event.target?.classList.contains(styles.filtersModal)) {
      close();
    }
  };
  const removeAll = () => {
    removeAllFilters();
    close();
  };

  const getAllFilters = async () => {
    setFiltersLoading(true);
    try {
      const path = router.asPath.split('?')[0].replace('/ekspress-dostavka', '');
      const location: string = typeof city === 'string' ? city : 'Москва';
      const activeQueryFilters = { ...activeFilters };
      let params: Record<string, string | number | boolean | string[]> = {};
      params = getParamsFromQuery(params, activeQueryFilters);
      const paramsFromQuery = getParamsFromQuery(params, activeQueryFilters);
      const allFiltersResp = await api.getCategoryFiltersByPath(path, location, paramsFromQuery);
      const allFiltersList = convertFiltersIfPrice(allFiltersResp.data.data) || [];
      if (!!allFiltersList?.length && !filtersList.length) {
        setFiltersList(allFiltersList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFiltersLoading(false);
    }
  };

  const applyAll = () => {
    if (!localCurrentFilters) {
      close();
      return;
    }
    const pathname = router.asPath.split('?')[0];
    const query = {};
    for (const key in localCurrentFilters) {
      const displayType = localCurrentFilters[key].display_type;
      const valueType = localCurrentFilters[key].value_type;
      const values = localCurrentFilters[key].values;
      if (!values.length) continue;
      switch (displayType) {
        case 'MANY_FROM_MANY':
          query[key] = values;
          break;
        case 'NUMERIC_RANGE':
          if (valueType === 'PRICE') {
            query[key] = `${values[0] * 100}%-%${values[1] * 100}`;
            continue;
          }
          query[key] = `${values[0]}%-%${values[1]}`;
          break;
      }
    }

    router.push({ query, pathname });
    close();
  };

  useEffect(() => {
    if (isOpen) {
      if (!filtersList.length) {
        getAllFilters();
      }
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!filtersList.length) setFiltersList([]);
  }, [id]);
  return (
    <div className={`${styles.filtersModal} ${isOpen && styles.filtersModalOpen}`} onMouseDown={onModalClose}>
      <div className={styles.filtersContent} onClick={menuClickHandler}>
        {isFiltersLoading && (
          <div className={styles.filterLoader}>
            <Preloader />
          </div>
        )}
        <div className={styles.filterHeader}>
          <div className={styles.filtersClose} onClick={close}>
            <Icon name="Cross" />
          </div>
          <div className={styles.filtersTitle}>Сортировка и фильтры </div>
        </div>
        <div className={styles.filtersBlock}>
          {filtersList.map((filter) => (
            <Accordeon key={filter.id} title={filter.display_name || filter.name} className={styles.filtersAccordeon}>
              <div className={filter.display_type === 'MANY_FROM_MANY' ? styles.listWrapper : ''}>
                <Filter
                  filter={filter}
                  baseFilter={sortedBaseFilters.find((item) => item.id === filter.id)}
                  localCurrentFilter={localCurrentFilters?.[filter.id] || null}
                  localFilters={localCurrentFilters}
                  setLocalCurrentFilters={setLocalCurrentFilters}
                  addFilter={addFilter}
                  full
                />
              </div>
            </Accordeon>
          ))}
        </div>
        <div className={styles.filtersButtons}>
          <Button text="Применить фильтры" isFullScreen onClick={applyAll}></Button>
          <Button text="Очистить фильтры" isFullScreen onClick={removeAll}></Button>
        </div>
      </div>
    </div>
  );
};

export { FiltersModal };
