import { FC, Fragment, useEffect, useState } from 'react';
import Link from 'next/link';

import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';
import { parseTagTitle } from 'features/categories';

import styles from './styles.module.scss';
import { useRouter } from 'next/router';

export type BreadcrumbType = {
  title: string;
  href: string;
  clickableLast?: boolean;
};

type Props = {
  breadcrumbs: BreadcrumbType[];
};

const Breadcrumbs: FC<Props> = ({ breadcrumbs }) => {
  const [isFallbackInWindow, setFallBackInWindow] = useState(false);
  useEffect(() => {
    if (window.myToBack) {
      setFallBackInWindow(true);
    }
  }, []);
  const router = useRouter();
  return (
    <div className="container">
      <nav className={styles.breadcrumbs}>
        {isFallbackInWindow && (
          <a
            className={`${styles.myToBackButton}`}
            onClick={() => {
              window.myToBack(router);
            }}
          >
            <Icon name="ArrowLeft" /> Назад к Результатам
          </a>
        )}

        <Link href="/" className={styles.breadcrumb}>
          Главная
        </Link>
        {breadcrumbs?.map((breadcrumb: BreadcrumbType) => (
          <Fragment key={breadcrumb.title}>
            <div key={breadcrumb.title} className={styles.breadcrumbsSeparator}>
              <Icon name="SmallArrowRight" />
            </div>
            <Link
              key={'title' + breadcrumb.title}
              href={breadcrumb.href}
              className={`${styles.breadcrumb} ${breadcrumb.clickableLast ? styles.clickable : ''}`}
              dangerouslySetInnerHTML={{ __html: parseTagTitle(breadcrumb.title) }}
            ></Link>
          </Fragment>
        ))}
      </nav>
    </div>
  );
};

export { Breadcrumbs };
