import React from 'react';

export const ExpressFilterSVG = () => {
    return (
        <svg width="120" height="28" viewBox="0 0 120 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 6C12 2.68629 14.6863 0 18 0H106.007C107.956 0 109.784 0.946988 110.908 2.53942L117.371 11.6929C118.348 13.076 118.348 14.924 117.371 16.3071L110.908 25.4606C109.784 27.053 107.956 28 106.007 28H18C14.6863 28 12 25.3137 12 22V6Z"
                fill="#CEEDF6"/>
            <path
                d="M6 6C6 2.68629 8.68629 0 12 0H100.007C101.956 0 103.784 0.946988 104.908 2.53942L111.371 11.6929C112.348 13.076 112.348 14.924 111.371 16.3071L104.908 25.4606C103.784 27.053 101.956 28 100.007 28H12C8.6863 28 6 25.3137 6 22V6Z"
                fill="#68C9D7"/>
            <path
                d="M0 6C0 2.68629 2.68629 0 6 0H94.007C95.9563 0 97.7842 0.946988 98.9085 2.53942L105.371 11.6929C106.348 13.076 106.348 14.924 105.371 16.3071L98.9085 25.4606C97.7842 27.053 95.9563 28 94.007 28H6.00001C2.6863 28 0 25.3137 0 22V6Z"
                fill="#14B1B1"/>
        </svg>
    )
}