import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import type { Variant } from '@nebo-team-dev/vobaza.ui.inputs.input-select';
import type { IFilter, IFilterFront } from 'entities/filters/model/IFilter';
import { GoodsSortTypes } from 'entities/products/model/IGood';
import { sortFiltersItems } from 'widgets/categories/lib/sortFiltersItems';

import { FilterSelect } from '@nebo-team-dev/vobaza.ui.filter-select';
import { Icon } from '@nebo-team-dev/vobaza.ui.icon';
import { Filter } from '../../../../entities/filters/ui/Filter';
import filterButtonStyles from 'global/styles/FilterButton.module.scss';

import styles from './styles.module.scss';

const getSortVariants = () => {
  return Object.entries(GoodsSortTypes).map(([key, value]) => ({ code: key, value }));
};

type Props = {
  filters: IFilter[];
  baseFilters: IFilter[];
  currentSort: Variant<string>;
  toggleMenu: () => void;
  setSort: (value: Variant<string>) => void;
  addFilter: (filters: IFilterFront) => void;
  setLocalCurrentFilters: (filters: Record<number, IFilterFront>) => void;
  localCurrentFilters: Record<number, IFilterFront>;
};

const CategoryFiltersList = ({
  filters,
  baseFilters,
  currentSort,
  toggleMenu,
  setSort,
  addFilter,
  setLocalCurrentFilters,
  localCurrentFilters,
}: Props) => {
  const sortVariants = useMemo(getSortVariants, []);
  // const slicedFilters = filters.slice(0, 7);
  const router = useRouter();
  const { id, city, page, sort, text, ...activeFilters } = router.query;

  const sortedBaseFilters = sortFiltersItems(baseFilters);

  // FIXME: После переноса UI кита пришлось сделать временно эту прослойку
  const handleChangeSort = (value) => setSort(value);

  return (
    <div className={styles.filtersBlock}>
      <div className={styles.filters}>
        {filters.map(
          (filter) =>
            filter.visibility_type === 'MAIN' && (
              <Filter
                key={filter.id}
                filter={filter}
                baseFilter={sortedBaseFilters.find((item) => item.id === filter.id)}
                localCurrentFilter={localCurrentFilters?.[filter.id]}
                addFilter={addFilter}
                setLocalCurrentFilters={setLocalCurrentFilters}
                localFilters={localCurrentFilters}
              />
            )
        )}

        <button className={filterButtonStyles.filtersButton} onClick={toggleMenu}>
          Еще фильтры
        </button>
      </div>
      <button className={styles.filtersMobileButton} onClick={toggleMenu}>
        <Icon name="Filters" />
        Фильтры
        {activeFilters && Object.keys(activeFilters).length > 0 && (
          <span className={styles.filtersBadge}>{Object.keys(activeFilters).length}</span>
        )}
      </button>
      <div className={styles.sort}>
        <FilterSelect
          className={styles.sortItem}
          variation="secondary"
          variants={sortVariants}
          onChange={handleChangeSort}
          selected={currentSort}
          isRightSide
        />
      </div>
    </div>
  );
};

export { CategoryFiltersList };
