import React, { FC } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import type { ICategory } from 'entities/categories/model/ICategory';
import type { ITag } from 'entities/tags';
import { parseTagTitle } from 'features/categories';

import expressBanner from 'assets/images/express-banner.webp';
import expressBannerMobile from 'assets/images/express-banner_mobile.webp';

import CatalogList from 'components/Catalog/List';

import styles from './styles.module.scss';

type Props = {
  currentTag: ITag;
  category: ICategory;
  filtersTitle: string;
};

const CategoryHeader: FC<Props> = ({
  currentTag,
  category,
}) => {
  const router = useRouter();
  const { page } = router.query;

  return (
    <>
      <div className={styles.bannerBlockMobile}>
        <Image src={expressBannerMobile} alt="Banner" />
      </div>
      {currentTag?.page_title ? (
        <h1
          className={styles.sectionTitle}
          dangerouslySetInnerHTML={{ __html: parseTagTitle(currentTag?.page_title) }}
        />
      ) : (
        <h1 className={styles.sectionTitle}>
          {category?.name} {page && page !== '1' && ` – страница ${page}`}
        </h1>
      )}
      {category?.children?.length > 0 && <CatalogList list={category.children} />}
      <div className={styles.bannerBlock}>
        <Image src={expressBanner} alt="Banner" />
      </div>
    </>
  );
};

export { CategoryHeader };
