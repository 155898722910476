
const tagRegs = {
  strong: /(^|[^\\](\\{2})*)(\*{2}(([^\\]|\\{2})*?)\*{2})/gi,
  em: /(^|[^\\](\\{2})*)(_{2}(([^\\]|\\{2})*?)_{2})/gi,
  b: /(^|[^\\](\\{2})*)(\*{1}(([^\\]|\\{2})*?)\*{1})/gi,
  i: /(^|[^\\](\\{2})*)(_{1}(([^\\]|\\{2})*?)_{1})/gi,
};

export const parseTagTitle = (text: string) => {
  const tagsEntries = Object.entries(tagRegs);
  const stringTitle = tagsEntries.reduce((acc, item) => 
    acc.replace(item[1], `$1<${item[0]}>$4</${item[0]}>`)
  , text || '').replace(/\\([*_\\])/gi, `$1`);

  return stringTitle;
};