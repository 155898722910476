import { IFilter } from "entities/filters";

export const convertFiltersIfPrice = (filters: IFilter[] = []) => {
  return filters.map((filter) =>
    filter.value_type === 'PRICE'
      ? {
          ...filter,
          meta:
            filter.display_type === 'NUMERIC_RANGE'
              ? {
                  min: +(filter.meta.min / 100).toFixed(),
                  max: +(filter.meta.max / 100).toFixed(),
                }
              : {
                  ...filter.meta,
                  items: filter.meta.items.map((item) => (Number(item) / 100).toString()),
                },
        }
      : filter
  );
};